import axios from "../api/api";

const modifyCustomer = async (customer) => {
  const data = { ...customer };
  data.id = Number(data.id);
  const response = await axios.post(
    `/customer?id=${customer.id}`,
    JSON.stringify(data),
    {
      headers: {
        "Content-Type": "text/plain",
        Accept: "application/json, text/plain, */*",
      },
    }
  );

  return response;
};

export default modifyCustomer;
