import { useState } from "react";
import { useQuery } from "react-query";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import "../css/history.css";
import fetchOrder from "../fetcher/fetchOrder";
import useData from "../context/useData";

export default function History(props) {
  const { data } = useQuery("order", fetchOrder);
  const history = data?.data;

  const { productInfo } = useData();

  const [filter, setFilter] = useState("");

  const rows = filterOrderHistory(history, filter, productInfo);

  const navigate = useNavigate();

  const handleClick = (id) => {
    if (props.mobileMenuOpen) {
      props.setMobileMenuOpen(!props.mobileMenuOpen);
      return;
    }
    navigate(`/history/${id}`);
  };

  return (
    <div className="history">
      <h2>Order History</h2>
      <div className="history-container">
        <div className="table-search">
          <input
            placeholder="Search..."
            onChange={(e) => setFilter(e.target.value)}
          />
          <FaSearch className="table-search-icon" />
        </div>

        <div className="history-table">
          <table>
            <tbody>
              <tr>
                <th style={{ maxWidth: "120px" }}>Customer</th>
                <th style={{ width: "65px" }}>Date</th>
                <th style={{ width: "60px" }}>Sales</th>
                <th style={{ width: "75px" }}>Delivery</th>
                {productInfo?.map((product) => {
                  return (
                    <th className="table-product" key={product.DbId}>
                      {product.ShortName}
                    </th>
                  );
                })}
              </tr>

              {rows !== null &&
                rows.map((row) => {
                  return (
                    <tr
                      key={row.id}
                      className={
                        row?.complete?.length
                          ? "delivered"
                          : row?.comment?.length
                          ? "comment"
                          : ""
                      }
                      onClick={() => {
                        handleClick(row.id);
                      }}
                    >
                      {Object.keys(row).map((key) => {
                        if (key === "id" || key === "comment") {
                          return null;
                        }
                        return (
                          <td key={key}>
                            {Number(row[key]) !== 0 ? row[key] : ""}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function reverseOrderHistory(history, productsList) {
  const rows = history?.toReversed().map((item) => {
    let row = {
      id: item.DbId,
      customer: item.customer,
      date: item.date,
      sales: item.sales,
      complete: item.complete,
      comment: item.comment,

      // "1750ml French Laundry": 0,
      // "1140ml French Laundry": 0,
      // "750ml French Laundry": 0,
      // "375ml French Laundry": 0,
      // "1750ml QC Vodka": 0,
      // "750ml QC Vodka": 0,
      // "375ml QC Vodka": 0,

      // "Sperling Brandy OS": 0,
      // "1140ml Holy Spirits": 0,
      // "750ml Holy Spirits": 0,
      // "375ml Holy Spirits": 0,
      // "Golden Stag Rye Whisky 5 Years": 0,
      // "4pk FL Lager": 0,
      // "4pk FL Pale Ale": 0,
      // "4pk FL Stout": 0,
      // "4pk FL Strong Beer": 0,
      // "4pk FL White IPA": 0,
    };

    productsList.forEach((product) => {
      row[product.Name] = 0;
    });

    console.log(row);

    item.products.forEach((product) => {
      row[product.name] = product.quantity;
    });

    return row;
  });

  return rows;
}

function filterOrderHistory(history, filter, productsList) {
  if (!history || !productsList) {
    return null;
  }
  const rows = reverseOrderHistory(history, productsList);

  if (!filter.length) {
    return rows;
  }

  const filteredRows = rows.filter((row) => {
    return row.customer.toLowerCase().includes(filter.toLowerCase());
  });

  return filteredRows.length ? filteredRows : rows;
}

export const loader = (queryClient) => async () => {
  const res =
    queryClient.getQueryData("order") ??
    (await queryClient.fetchQuery("order", fetchOrder));
  return res;
};
