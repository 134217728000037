import { useState } from "react";
import { useQueryClient } from "react-query";

import "../css/form.css";
import { getDate } from "./getDate";
import useAuth from "../context/useAuth";
import useData from "../context/useData";
import submitOrder from "../fetcher/submitOrder";
import OrderConfirmation from "./orderConfirmation";

export default function OrderForm() {
  const queryClient = useQueryClient();
  const data = useData();

  const customersList = data.customerInfo;
  const productsList = data.productInfo;

  const [filteredCustomers, setFilteredCustomers] = useState();
  const [filteredProducts, setFilteredProducts] = useState();

  const [searchProduct, setSearchProduct] = useState("");
  const [orderFor, setOrderFor] = useState("");

  const [productsAdded, setProductsAdded] = useState([]);
  const [productsInOrder, setProductsInOrder] = useState([]);

  const [comment, setComment] = useState("");

  const [notification, setNotification] = useState({});
  const [orderConfirmed, setOrderConfirmed] = useState(false);

  const user = useAuth();

  const chooseCustomer = (e) => {
    setOrderFor(e.target.value);
    setFilteredCustomers(
      customersList.filter((customer) => {
        return customer.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      })
    );
  };

  const chooseProduct = (e) => {
    setSearchProduct(e.target.value);
    setFilteredProducts(
      productsList?.filter((product) => {
        return product.Name.toLowerCase().includes(
          e.target.value.toLowerCase()
        );
      })
    );
  };

  const showCustomersList = () => {
    setFilteredProducts(null);
    if (orderFor.length) {
      setFilteredCustomers(
        customersList?.filter((customer) => {
          return customer.name.toLowerCase().includes(orderFor.toLowerCase());
        })
      );
      return;
    }
    setFilteredCustomers(customersList);
  };

  const showProductsList = () => {
    if (searchProduct.length) {
      setFilteredProducts(
        productsList?.filter((product) => {
          return product.Name.toLowerCase().includes(
            searchProduct.toLowerCase()
          );
        })
      );
      return;
    }
    setFilteredProducts(productsList);
  };

  const handleCustomerSelection = (row) => {
    setOrderFor(row.name);
  };

  const addProduct = (row) => {
    let flag = false;
    productsAdded.forEach((product) => {
      if (product.name === row.Name) {
        flag = true;
        setProductsAdded(
          productsAdded.filter((item) => {
            if (item.name !== row.Name) {
              return item;
            } else {
              return null;
            }
          })
        );
      }
    });

    if (!flag) {
      setProductsAdded([
        ...productsAdded,
        {
          DbId: row.DbId,
          name: row.Name,
          bottles: "",
          cases: "",
          CaseQuantity: row.CaseQuantity,
        },
      ]);
    }
  };

  const addToOrder = () => {
    productsAdded.forEach((productAdded) => {
      let flag = false;
      productsInOrder.forEach((productInOrder) => {
        if (productAdded.DbId === productInOrder.DbId) {
          flag = true;
          return;
        }
      });

      if (!flag) {
        setProductsInOrder((prev) => {
          return [productAdded, ...prev];
        });
      }
    });
    clearSearchProduct();
  };

  const clearSearchProduct = () => {
    setFilteredProducts(null);
    setSearchProduct("");
    setProductsAdded([]);
  };

  const resetForm = () => {
    setOrderFor("");
    setSearchProduct("");
    setFilteredCustomers(null);
    setFilteredProducts(null);
    setProductsAdded([]);
    setProductsInOrder([]);
    setComment("");
    setNotification({});
  };

  const changeCases = (e, product) => {
    let bottles, cases;
    if (!Number(e.target.value)) {
      bottles = 0;
      cases = 0;
    } else {
      bottles = (e.target.value * product.CaseQuantity).toFixed(2);
      cases = e.target.value;
    }

    setProductsInOrder(
      productsInOrder.map((item) => {
        if (item.DbId === product.DbId) {
          const newProduct = { ...item };
          newProduct.cases = cases;
          newProduct.bottles = bottles;

          return newProduct;
        }
        return item;
      })
    );
  };

  const changeBottles = (e, product) => {
    let cases, bottles;
    if (!Number(e.target.value)) {
      cases = 0;
      bottles = 0;
    } else {
      cases = (e.target.value / product.CaseQuantity).toFixed(2);
      bottles = e.target.value;
    }

    setProductsInOrder(
      productsInOrder.map((item) => {
        if (item.DbId === product.DbId) {
          const newProduct = { ...item };
          newProduct.cases = cases;
          newProduct.bottles = bottles;

          return newProduct;
        }
        return item;
      })
    );
  };

  const deleteTableRow = (product) => {
    setProductsInOrder(
      productsInOrder.filter((item) => {
        if (item.DbId !== product.DbId) {
          return item;
        } else {
          return null;
        }
      })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let flag = false;

    const products = productsInOrder.map((item) => {
      if (!Number(item.bottles)) {
        flag = true;
      }
      return { name: item.name, quantity: Number(item.bottles) };
    });

    if (flag || !orderFor.length || !products.length) {
      setNotification({
        msg: "Please choose a customer and fill out quantity for each product before submit an order",
        color: "#D80303",
      });

      setTimeout(() => {
        setNotification({});
      }, 3500);

      return;
    }

    const order = {
      customer: orderFor,
      sales: user.user.username,
      comment: comment,
      date: getDate(),
      products: products,
    };

    try {
      await submitOrder(order);

      await queryClient.invalidateQueries("order");
      setOrderConfirmed(true);
      resetForm();
    } catch (err) {
      console.log(err);
      if (!err?.response) {
        setNotification({
          msg: "Network connetion lost. Please check the Internet connection and try again later.",
          color: "#D80303",
        });
      } else if (err.response?.data) {
        setNotification({
          msg: err.response.data,
          color: "#D80303",
        });
      } else {
        setNotification({
          msg: "Unexpected error. Please try again later.",
          color: "#D80303",
        });
      }

      setTimeout(() => {
        setNotification({});
      }, 5000);
    }
  };

  if (orderConfirmed) {
    return <OrderConfirmation setOrderConfirmed={setOrderConfirmed} message="Order confirmed. Good job!" />;
  }

  return (
    <div className="form">
      <span
        className={notification.msg?.length ? "notification" : "hidden"}
        style={{ backgroundColor: notification.color }}
      >
        {notification.msg}
      </span>
      <h2>Wholesale Order Form</h2>

      <form className="order-form" onSubmit={handleSubmit}>
        <div className="order-form-container">
          <div className="order-form-header">
            <div className="sales">
              <label>Sales Rep:</label>
              <input name="sales" value={user.user?.username} readOnly />
            </div>

            <div className="customer">
              <div>
                <label>Customer:</label>
                <input
                  type="text"
                  name="customer"
                  value={orderFor}
                  onChange={chooseCustomer}
                  onFocus={showCustomersList}
                  onBlur={() => {
                    setFilteredCustomers(null);
                  }}
                />
              </div>

              {filteredCustomers ? (
                <div className="customers-list">
                  {filteredCustomers.map((row) => {
                    return (
                      <p
                        key={row.id}
                        onMouseDown={() => {
                          handleCustomerSelection(row);
                        }}
                      >
                        {row.name}
                      </p>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="date">
              <label>Date:</label>
              <input name="date" value={getDate()} readOnly />
            </div>
          </div>

          <div className="order-form-body">
            <div>
              <button type="submit" className="submit">Submit</button>
              <button className="reset-form" type="reset" onClick={resetForm}>
                Cancel
              </button>
            </div>
            <div style={{ position: "relative" }}>
              <div className="search-product">
                <label>Add Item:</label>
                <input
                  value={searchProduct}
                  onChange={chooseProduct}
                  onFocus={showProductsList}
                />
              </div>

              {filteredProducts ? (
                <div className="products-list-container">
                  <div className="products-list">
                    {filteredProducts.map((row) => {
                      return (
                        <label key={row.DbId}>
                          <input
                            type="checkbox"
                            onChange={() => addProduct(row)}
                          />
                          {row.Name}
                        </label>
                      );
                    })}
                  </div>
                  <button
                    className="products-list-add"
                    type="button"
                    onClick={addToOrder}
                  >
                    Add
                  </button>
                  <button
                    className="products-list-cancel"
                    type="button"
                    onClick={clearSearchProduct}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {productsInOrder.length > 0 && (
          <div className="table-container">
            <table className="order-form-table">
              <tbody>
                <tr>
                  <th style={{ width: "40%" }}>Description</th>
                  <th style={{ width: "20%" }}>Cases</th>
                  <th style={{ width: "20%" }}>Qty</th>
                  <th style={{ width: "10%" }}>Action</th>
                </tr>

                {productsInOrder.map((product) => {
                  return (
                    <tr key={product.DbId}>
                      <td style={{ width: "40%" }}>{product.name}</td>
                      <td style={{ width: "20%" }}>
                        <input
                          value={
                            Number(product.cases) ? Number(product.cases) : ""
                          }
                          onChange={(e) => changeCases(e, product)}
                        />
                      </td>
                      <td style={{ width: "20%" }}>
                        <input
                          value={
                            Number(product.bottles)
                              ? Number(product.bottles)
                              : ""
                          }
                          onChange={(e) => changeBottles(e, product)}
                        />
                      </td>
                      <td style={{ width: "5%" }}>
                        <button
                          type="button"
                          onClick={() => {
                            deleteTableRow(product);
                          }}
                        >
                          x
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        <div className="order-form-footer">
          <textarea
            className="message"
            name="message"
            value={comment}
            rows={6}
            cols={40}
            placeholder="Message:"
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      </form>
    </div>
  );
}

// export const loader = (queryClient) => async () => {
//   const res =
//     queryClient.getQueryData("form") ??
//     (await queryClient.fetchQuery("form", initialization));
//   return res;
// };
