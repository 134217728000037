import axios from "../api/api";

const createCustomer = async (customer) => {
  const data = { ...customer };
  delete data.id;
  const response = await axios.post(`/customer`, JSON.stringify(data), {
    headers: {
      "Content-Type": "text/plain",
      Accept: "application/json, text/plain, */*",
    },
  });

  return response;
};

export default createCustomer;
