import { useState } from "react";
import { Form, useActionData } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import validator from "validator";

import "../css/setting.css";
import useData from "../context/useData";
import modifyCustomer from "../fetcher/modifyCustomer";
import createCustomer from "../fetcher/createCustomer";
import createProduct from "../fetcher/createProduct";
import modifyProduct from "../fetcher/modifyProduct";

export default function Setting() {
  const [tab, setTab] = useState(1);
  const [search, setSearch] = useState("");

  const [customer, setCustomer] = useState({});
  const [product, setProduct] = useState({});

  const [list, setList] = useState([]);

  const [showCustomerDelete, setShowCustomerDelete] = useState(false);
  const [showProductDelete, setShowProductDelete] = useState(false);

  const { customerInfo } = useData();
  const { productInfo } = useData();

  console.log(customer);
  console.log(product);

  const error = useActionData();

  console.log(error);

  const handleSearch = (e) => {
    setSearch(e.target.value);

    if (tab === 1) {
      setList(
        customerInfo.filter((customer) => {
          return (
            customer.name
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            customer.city.toLowerCase().includes(e.target.value.toLowerCase())
          );
        })
      );
    } else {
      setList(
        productInfo.filter((product) => {
          return product.Name.toLowerCase().includes(
            e.target.value.toLowerCase()
          );
        })
      );
    }
  };

  const showList = () => {
    if (search.length) {
      if (tab === 1) {
        setList(
          customerInfo.filter((customer) => {
            return (
              customer.name.toLowerCase().includes(search.toLowerCase()) ||
              customer.city.toLowerCase().includes(search.toLowerCase())
            );
          })
        );
      } else {
        setList(
          productInfo.filter((product) => {
            return product.Name.toLowerCase().includes(search.toLowerCase());
          })
        );
      }
    } else {
      if (tab === 1) {
        setList(customerInfo);
      } else {
        setList(productInfo);
      }
    }
  };

  const handleSelection = (item) => {
    if (tab === 1) {
      setCustomer(item);
      setShowCustomerDelete(true);
    } else {
      setProduct(item);
      setShowProductDelete(true);
    }

    resetSearch();
  };

  const modeifyCustomer = (e) => {
    setCustomer({ ...customer, [e.target.name]: e.target.value });
  };

  const modifyProduct = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const resetCustomerForm = () => {
    setCustomer({});
    setShowCustomerDelete(false);
  };

  const resetProductForm = () => {
    setProduct({});
    setShowProductDelete(false);
  };

  const resetSearch = () => {
    setList([]);
    setSearch("");
  };

  return (
    <div className="setting">
      <h2>Settings</h2>
      <div className="setting-container">
        <div className="setting-container-header">
          <div className="tabs">
            <button
              onClick={() => {
                setTab(1);
                resetSearch();
              }}
              style={{
                backgroundColor:
                  tab === 1 ? "rgb(230, 247, 253)" : "rgb(221, 221, 221)",
              }}
            >
              Customers
            </button>
            <button
              onClick={() => {
                setTab(2);
                resetSearch();
              }}
              style={{
                backgroundColor:
                  tab === 2 ? "rgb(230, 247, 253)" : "rgb(221, 221, 221)",
              }}
            >
              Products
            </button>
          </div>

          <div className="setting-search">
            <div>
              <input
                type="text"
                placeholder="Search..."
                value={search}
                onChange={handleSearch}
                onBlur={() => setList([])}
                onFocus={showList}
              />
              <FaSearch className="setting-search-icon" />
            </div>
            {list.length > 0 && (
              <div className="search-result">
                {list.map((item, index) => {
                  return (
                    <p
                      key={index}
                      onMouseDown={() => {
                        handleSelection(item);
                      }}
                    >
                      {tab === 1 ? item.name : item.Name}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className={tab === 1 ? "setting-container-body" : "hidden"}>
          <Form method="post">
            <div className="setting-form">
              <div>
                <label>Customer ID:</label>
                <input
                  type="text"
                  name="custId"
                  value={customer.custId ?? ""}
                  onChange={modeifyCustomer}
                />
                {error?.custId?.length && <span>{error?.custId}</span>}
              </div>

              <div>
                <label>Customer Name:</label>
                <input
                  type="text"
                  name="name"
                  value={customer.name ?? ""}
                  onChange={modeifyCustomer}
                />
                {error?.name?.length && <span>{error?.name}</span>}
              </div>

              <div>
                <label>City:</label>
                <input
                  type="text"
                  name="city"
                  value={customer.city ?? ""}
                  onChange={modeifyCustomer}
                />
                {error?.city?.length && <span>{error?.city}</span>}
              </div>

              <div>
                <label>Email Address:</label>
                <input
                  type="text"
                  name="email"
                  value={customer.email ?? ""}
                  onChange={modeifyCustomer}
                />
                {error?.email?.length && <span>{error?.email}</span>}
              </div>

              <div>
                <label>Phone Number:</label>
                <input
                  type="text"
                  name="phone"
                  value={customer.phone ?? ""}
                  onChange={modeifyCustomer}
                />
                {error?.phone?.length && <span>{error?.phone}</span>}
              </div>
            </div>

            <div className="setting-form-button">
              <button
                type="submit"
                name="id"
                value={customer.id ?? ""}
                className="setting-form-submit"
              >
                Save
              </button>
              <button
                type="button"
                className="setting-form-cancel"
                onClick={resetCustomerForm}
              >
                Cancel
              </button>
            </div>
          </Form>

          {showCustomerDelete && (
            <Form className="delete-form" action="destroy" method="post">
              <button type="submit" className="setting-form-delete" name="id" value={customer.id}>
                Delete
              </button>
            </Form>
          )}
        </div>
        <div className={tab === 2 ? "setting-container-body" : "hidden"}>
          <Form method="post">
            <div className="setting-form">
              <div>
                <label>Product Name:</label>
                <input
                  type="text"
                  name="Name"
                  value={product.Name ?? ""}
                  onChange={modifyProduct}
                />
                {error?.Name?.length && <span>{error?.Name}</span>}
              </div>

              <div>
                <label>Case Quantity:</label>
                <input
                  type="text"
                  name="CaseQuantity"
                  value={product.CaseQuantity ?? ""}
                  onChange={modifyProduct}
                />
                {error?.CaseQuantity?.length && (
                  <span>{error?.CaseQuantity}</span>
                )}
              </div>

              <div>
                <label>Short Name:</label>
                <input
                  type="text"
                  name="ShortName"
                  value={product.ShortName ?? ""}
                  onChange={modifyProduct}
                />
                {error?.ShortName?.length && (
                  <span>{error?.ShortName}</span>
                )}
              </div>
            </div>

            <div className="setting-form-button">
              <button
                type="submit"
                name="DbId"
                value={product.DbId ?? ""}
                className="setting-form-submit"
              >
                Save
              </button>
              <button
                type="button"
                className="setting-form-cancel"
                onClick={resetProductForm}
              >
                Cancel
              </button>
            </div>
          </Form>

          {showProductDelete && (
            <Form className="delete-form" action="destroy" method="post">
              <button type="submit" className="setting-form-delete" name="DbId" value={product.DbId}>
                Delete
              </button>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
}

export const action =
  (queryClient) =>
  async ({ request }) => {
    const data = await request.formData();
    const update = Object.fromEntries(data);
    const error = valideForm(update);

    if (Object.keys(error).length) {
      return error;
    }

    console.log(update);
    if (Object.keys(update).length > 4) {
      if (update.id.length) {
        await modifyCustomer(update);
      } else {
        await createCustomer(update);
      }
    } else {
      if (update.DbId.length) {
        await modifyProduct(update);
      } else {
        await createProduct(update);
      }
    }
    await queryClient.invalidateQueries("init");
    window.location.reload();
    return null;
  };

function valideForm(update) {
  const error = {};
  if (Object.keys(update).length > 4) {
    if (!update.city.length) {
      error.city = "City cannot be empty.";
    }

    if (!update.custId.length) {
      error.custId = "Custoner ID cannot be empty.";
    }

    if (!update.name.length) {
      error.name = "Customer Name cannot be empty.";
    }

    if (!validator.isMobilePhone(update.phone)) {
      error.phone = "Customer Phone number is invalide.";
    }

    if (!validator.isEmail(update.email)) {
      error.email = "Customer Email is invalide.";
    }
  } else {
    if (!Number(update.CaseQuantity)) {
      error.CaseQuantity = "Case Quantity must be a number.";
    }
    if (Number(update.CaseQuantity) <= 0) {
      error.CaseQuantity = "Case Quantity must be a positive number.";
    }
    if (!update.Name.length) {
      error.Name = "Product Name cannot be empty.";
    }
    if (!update.ShortName.length) {
      error.ShortName = "Product Short Name cannot be empty.";
    }
  }

  return error;
}
