import axios from "../api/api";

const modifyProduct = async (product) => {
  const data = { ...product };
  data.DbId = Number(data.DbId);
  data.CaseQuantity = Number(data.CaseQuantity);
  console.log(data);
  const response = await axios.post(
    `/product?id=${product.DbId}`,
    JSON.stringify(data),
    {
      headers: {
        "Content-Type": "text/plain",
        Accept: "application/json, text/plain, */*",
      },
    }
  );

  return response;
};

export default modifyProduct;
