import axios from "../api/api";

const createProduct = async (product) => {
  const data = { ...product };
  delete data.DbId;
  data.CaseQuantity = Number(data.CaseQuantity);
  const response = await axios.post(`/product`, JSON.stringify(data), {
    headers: {
      "Content-Type": "text/plain",
      Accept: "application/json, text/plain, */*",
    },
  });

  return response;
};

export default createProduct;
