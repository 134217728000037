import axios from "../api/api";

const initialization = async () => {
  const response = await axios.get("/initialize", {
    headers: {
      "Content-Type": "text/plain",
      Accept: "application/json, text/plain, */*",
    },
  });

  return response;
};

export default initialization;
