import { redirect } from "react-router-dom";
import deleteCustomer from "../fetcher/deleteCustomer";
import deleteProduct from "../fetcher/deleteProduct";

export const action =
  (queryClient) =>
  async ({ request }) => {
    const data = await request.formData();
    const target = Object.fromEntries(data);

    if (Object.keys(target)[0] === "id") {
      await deleteCustomer(target.id);
    } else {
      await deleteProduct(target.DbId);
    }

    await queryClient.invalidateQueries("init");
    window.location.reload();
    return redirect("/setting");
  };
