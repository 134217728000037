import { Outlet } from "react-router-dom";
import { PiSignOut } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { GiHamburgerMenu } from "react-icons/gi";
import { useEffect } from "react";

import "../css/root.css";
import Navbar from "../components/navbar";
import useAuth from "../context/useAuth";
import useData from "../context/useData";
import initialization from "../fetcher/initialization";

export default function Root(props) {
  const user = useAuth();
  const navigate = useNavigate();

  const { data } = useQuery("init", initialization);
  const initialize = useData();

  useEffect(() => {
    initialize.initializeData(data.data.Customers, data.data.ProductInfo);
  }, [data.data.Customers, data.data.ProductInfo, initialize]);

  const signOut = () => {
    user.logout();
    navigate("/login", { replace: true });
  };

  document.documentElement.style.setProperty(
    "--show",
    props.mobileMenuOpen ? "flex" : "none"
  );

  return (
    <div className="layout">
      <div
        className="mobile"
        onClick={() => {
          props.setMobileMenuOpen(!props.mobileMenuOpen);
        }}
      >
        <GiHamburgerMenu />
      </div>
      <div className="navbar">
        <Navbar />
      </div>
      <div
        className="outlet"
        onClick={() => {
          if (props.mobileMenuOpen) {
            props.setMobileMenuOpen(false);
          }
        }}
      >
        <div className="signout" onClick={signOut}>
          <PiSignOut className="signout-btn" />
          <p>Signout</p>
        </div>

        <Outlet />
      </div>
      {/* <button onClick={sendData}>Click</button> */}
    </div>
  );
}

export const loader = (queryClient) => async () => {
  const res =
    queryClient.getQueryData("init") ??
    (await queryClient.fetchQuery("init", initialization));
  return res;
};
