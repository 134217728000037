import { useState } from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../context/useAuth";
import { FaRegUserCircle } from "react-icons/fa";
import { RiLockPasswordLine } from "react-icons/ri";
import "../css/login.css";

export default function Login() {
  const user = useAuth();
  const [login, setLogin] = useState({ username: "", password: "" });

  if (user.user?.username) {
    return <Navigate to={"/"} replace={true} />;
  }

  const updateUsername = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    if (login.username !== "" && login.password !== "") {
      user.login(login);
    }
  };

  return (
    <>
      <br />
      <br />
      {user.errMsg && (
        <div className="error">
          <p>{user.errMsg}</p>
        </div>
      )}
      <h1 className="title">
        <img src={process.env.PUBLIC_URL + "/logo.png"} />
        Sperling Silver Wholesale Order Form
      </h1>
      <form className="login-form" onSubmit={handleSignIn}>
        <h2 className="login">Login</h2>

        <div className="username">
          <input
            name="username"
            placeholder="Username"
            onChange={updateUsername}
            required
          />
          <span>
            <FaRegUserCircle />
          </span>
        </div>

        <div className="password">
          <input
            type="password"
            name="password"
            placeholder="Password"
            onChange={updateUsername}
            required
          />
          <span>
            <RiLockPasswordLine />
          </span>
        </div>

        <br />
        <button type="submit">Login</button>
      </form>
    </>
  );
}
