import axios from "../api/api"

const submitOrder = async (order) => {
    const response = await axios.post("/order", JSON.stringify(order), {
        headers: { "Content-Type": "text/plain",  'Accept': 'application/json, text/plain, */*'},
    });

    return response;
}

export default submitOrder;