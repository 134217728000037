import axios from "../api/api"

const modifyOrder = async (order) => {
    const response = await axios.post(`/order?id=${order.DbId}`, JSON.stringify(order), {
        headers: { "Content-Type": "text/plain",  'Accept': 'application/json, text/plain, */*'},
    });

    return response;
}

export default modifyOrder;