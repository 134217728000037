import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { useState } from "react";
import { loader as rootLoader } from "./pages/root";
import { loader as orderLoader } from "./components/history";
import { loader as singleOrderLoader } from "./components/order";
import { QueryClient, QueryClientProvider } from "react-query";

import Login from "./pages/login";
import OrderForm from "./components/form";
import History from "./components/history";
import Order from "./components/order";
import Setting from "./components/setting";
import Root from "./pages/root";
import ErrorPage from "./pages/errorPage";
import WholeSaleOrder from "./components/wholeSaleOrder";
import RequireAuth from "./components/requireAuth";
import { action as settingAction } from "./components/setting";
import { action as destroyAction } from "./components/destroy";
import "./App.css";

function App() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const queryClient = new QueryClient();
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<WholeSaleOrder />} errorElement={<ErrorPage />}>
        <Route element={<RequireAuth />}>
          <Route
            path="/"
            element={
              <Root
                mobileMenuOpen={mobileMenuOpen}
                setMobileMenuOpen={setMobileMenuOpen}
              />
            }
            loader={rootLoader(queryClient)}
          >
            <Route index element={<OrderForm />} />
            <Route
              path="history"
              element={
                <History
                  mobileMenuOpen={mobileMenuOpen}
                  setMobileMenuOpen={setMobileMenuOpen}
                />
              }
              loader={orderLoader(queryClient)}
            />
            <Route
              path="history/:orderId"
              element={<Order />}
              loader={singleOrderLoader(queryClient)}
            />
            <Route
              path="setting"
              element={<Setting />}
              action={settingAction(queryClient)}
            />
            <Route path="setting/destroy"  action={destroyAction(queryClient)} />
          </Route>
        </Route>
        <Route path="login" element={<Login />} />
      </Route>
    )
  );

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
