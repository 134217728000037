import React from "react";
import { FaBoxes } from "react-icons/fa";
import { FaTable } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";

export const navData = [
    {
        title: "New Order",
        icon: <FaBoxes />,
        path: "/",
    },
    {
        title: "Order History",
        icon: <FaTable />,
        path: "/history",
    },
    {
        title: "Settings",
        icon: <IoMdSettings />,
        path: "/setting",
    },

]