export const contactData = [
    {
        email: "jeri@yqrdistillery.com",
        title: "Jeri Lee Winter",
    },
    {
        email: "dallas@yqrdistillery.com",
        title: "Dallas Reilly",
    },
    {
        email: "orders@yqrdistillery",
        title: "Order Desk",
    }, 
]