import { createContext, useState } from "react";

const DataContext = createContext(null);

export const DataProvider = ({ children }) => {
  const [customerInfo, setCustomerInfo] = useState(JSON.parse(localStorage.getItem("customerInfo")));
  const [productInfo, setProductInfo] = useState(JSON.parse(localStorage.getItem("productInfo")));

  const initializeData = (customers, products) => {
    setCustomerInfo(customers);
    setProductInfo(products);
    localStorage.setItem("productInfo", JSON.stringify(products));
    localStorage.setItem("customerInfo", JSON.stringify(customers));
  }

  return (
    <DataContext.Provider value={{ customerInfo, productInfo, initializeData }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
