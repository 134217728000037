import { NavLink } from "react-router-dom";
import { navData } from "./navData";
import { MdEmail } from "react-icons/md";
import { contactData } from "./contactData";

import "../css/navbar.css";

export default function Navbar() {
  return (
    <>
      <img className="logo" src={process.env.PUBLIC_URL + "/logo.png"} />
      <ul className="links">
        {navData.map((value) => {
          return (
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to={value.path}
              key={value.title}
            >
              <li className="link">
                <span className="link-icon">{value.icon}</span>
                <span className="link-title">{value.title}</span>
              </li>
            </NavLink>
          );
        })}
      </ul>

      <ul className="contacts">
        {contactData.map((value) => {
          return (
            <a href={`mailto:${value.email}`} key={value.title}>
              <li className="link">
                <span className="contact-icon">
                  <MdEmail />
                </span>
                <span className="contact-title">{value.title}</span>
              </li>
            </a>
          );
        })}
      </ul>
    </>
  );
}
