import { FaCheckCircle } from "react-icons/fa";

const OrderConfirmation = (props) => {
  setTimeout(() => {
      props.setOrderConfirmed(false);
  }, 3000);
  
  return (
    <div className="order-confirmation">
      <div>
        <FaCheckCircle style={{fontSize: "40px", color: "green"}} />
        <p style={{fontSize: "20px", padding: "10px"}}>{props.message}</p>
      </div>
    </div>
  );
};

export default OrderConfirmation;
