import axios from "../api/api";

const deleteOrder = async (id) => {
  const response = await axios.delete(`/order?id=${id}`, {
    headers: {
      "Content-Type": "text/plain",
      Accept: "application/json, text/plain, */*",
    },
  });

  return response;
};

export default deleteOrder;