import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./index.css";
import { AuthProvider } from "./context/authContext";
import { DataProvider } from "./context/dataContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <DataProvider>
        <App />
      </DataProvider>
    </AuthProvider>
  </React.StrictMode>
);
