import { createContext, useContext, useState } from "react";
import { fetchUser } from "../fetcher/fetchUser";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  // const [toke, setToken] = useState(localStorage.getItem("token"));

  const [errMsg, setErrMsg] = useState("");

  const login = async (userInfo) => {
    try {
      await fetchUser(userInfo);
      
      setUser(userInfo);
      setErrMsg("");
      // setToken(response.token);

      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      // localStorage.setItem("toke", res.token);
    } catch (err) {
      console.log(err);
      if (!err?.response) {
        setErrMsg(
          "Network connetion lost. Please check the Internet connection and try again later"
        );
      } else if (err.response?.data) {
        setErrMsg(err.response.data);
      } else {
        setErrMsg("Unexpected error. Please try again later.");
      }
    }
  };

  const logout = () => {
    setUser(null);
    //setToken("");
    localStorage.clear();
  };

  return (
    <AuthContext.Provider value={{ user, errMsg, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
